/**
 Bloomreach Edit Mode
 ------------------------------------------------------------
**/

.has-edit-button {
  position: relative;
}

.no-document {
  margin-bottom: var(--space-xxxl);
  &__config {
    text-align: center;
    display: flex;
  }
  &__component {
    align-items: center;
    background: var(--greyscale-5); //$ft__light-grey;
    border: 1px dashed var(--greyscale-3); //var(--greyscale-3);
    display: flex;
    height: 200px;
    text-align: center;
  }
  &__overlay {
    margin: auto;
  }
  strong {
    font-weight: 700;
  }
}
